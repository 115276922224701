import { isBrowser, isJsDom, isNode, isWebWorker } from "browser-or-node";
import { captureException, captureMessage } from "@sentry/astro";
import { defaultLogLevel, isLogLevelEnabled } from "./levels";
import { freezeInput } from "./utils";
import { type LogLevel } from "./types";

export const logger = (
  input: string | Error,
  logLevel: LogLevel = "error",
) => {
  const isError = input instanceof Error;
  const frozenInput = isError ? freezeInput(input) : input;
  const isClient = isBrowser || isWebWorker;
  const isServer = isNode || isJsDom;

  /** Deno and Bun are not supported runtimes */
  if (!isClient && !isServer) throw new Error("Unsupported runtime");

  /** Log to console when running build or dev server */
  if (
    (isClient &&
      import.meta.hot /** Check whether HMR e.g. dev server is active */ &&
      isLogLevelEnabled(defaultLogLevel, logLevel)) ||
    (isServer && isLogLevelEnabled(defaultLogLevel, logLevel))
  )
    logToConsole(frozenInput, logLevel);

  if (!isLogLevelEnabled(defaultLogLevel, logLevel)) return;

  if (isError) {
    captureException(input);
  } else {
    captureMessage(input);
  }
};

export const logToConsole = (
  input: string | Error,
  logLevel: LogLevel = "error",
) => {
  switch (logLevel) {
    case "trace":
      console.trace(input);
      break;
    case "debug":
      console.debug(input);
      break;
    case "info":
      console.info(input);
      break;
    case "warn":
      console.warn(input);
      break;
    case "error":
    default:
      console.error(input);
      break;
  }
};

import { type LogLevel } from "./types";

/**
 * Account for "verbose" or "silent" flags if running from CLI
 */
export let defaultLogLevel: LogLevel;
if (typeof process !== "undefined") {
  let proc: object = process;
  if ("argv" in proc && Array.isArray(proc.argv)) {
    if (proc.argv.includes("--verbose")) {
      defaultLogLevel = "debug";
    } else if (proc.argv.includes("--silent")) {
      defaultLogLevel = "silent";
    } else {
      defaultLogLevel = "error";
    }
  } else {
    defaultLogLevel = "error";
  }
} else {
  defaultLogLevel = "error";
}

export const levels: Record<LogLevel, number> = {
  info: 10,
  warn: 20,
  error: 30,
  debug: 40,
  trace: 50,
  silent: 60,
};

export function isLogLevelEnabled(
  configuredLogLevel: LogLevel,
  logLevel: LogLevel,
) {
  return levels[configuredLogLevel] <= levels[logLevel];
}

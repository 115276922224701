import chalk from "chalk";
import { type LogMessage } from "./types";

export const dateTimeFormat = new Intl.DateTimeFormat([], {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false,
});

/**
 * Get the prefix for a log message including a formatted timestamp, log level, and label.
 */
export function getEventPrefix({ level, label }: LogMessage) {
  const timestamp = `${dateTimeFormat.format(new Date())}`;
  const prefix = [];

  if (level === "error" || level === "warn") {
    prefix.push(chalk.bold(timestamp));
    prefix.push(`[${level.toUpperCase()}]`);
  } else {
    prefix.push(timestamp);
  }

  if (label) {
    prefix.push(`[${label}]`);
  }

  if (level === "error") {
    return chalk.red(prefix.join(" "));
  }

  if (level === "warn") {
    return chalk.yellow(prefix.join(" "));
  }

  if (prefix.length === 1) {
    return chalk.dim(prefix[0]);
  }

  return chalk.dim(prefix[0]) + " " + chalk.blue(prefix.splice(1).join(" "));
}

/**
 * Deep clone console input. Information about an object is lazily retrieved in the
 * console logger. This means that the log message shows the content of an object at
 * the time when it's first viewed, not when it was logged.
 */
export const freezeInput = (input: string | Error) => {
  return JSON.parse(JSON.stringify(input));
};

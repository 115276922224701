export const SegmentEvents = {
  // searches and eligibility checks
  FORM_SUBMITTED: "Form Submitted",
  // or buttons and links
  USER_CLICKED: "User Clicked",
  // for typed text into an input box
  USER_INPUTTED: "User Inputted",
  // for when the provider profile card comes into the viewport
  USER_VIEWED: "User Viewed",
};
